import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PortfolioItemSummary from "../../components/PortfolioItemSummary"
import LogoDSM from "../../images/logo-dsm.svg"

const PortfolioDSM = () => {
  return (
    <Layout bgClass="bg-dsm-lightblue" fgMode="light">
      <Seo 
        title="The Royal DSM"
        description="A platform designed to aid with safety"
      />
      <section className="text-center text-dsm-darkblue bg-dsm-lightblue">
        <div className="flex flex-col items-center mx-auto lg:max-w-screen-lg">
          <LogoDSM className="mx-auto" height={120}/>
          <h1 className="mt-8 text-4xl font-semibold">DSM Nutritional Products</h1>
          <p className="mx-2 mt-2 text-2xl text-center">The purpose-led, performance-driven company.</p>
        </div>
        <PortfolioItemSummary 
            expertise="UX and Development" 
            platforms="Web" 
            deliverables="API, Frontend" 
            href="https://www.dsm.com/corporate/about/businesses/dsm-nutritional-products.html" 
            linkText="DSM Nutritional" />          
      </section>
      <div className="w-full pb-16 text-dsm-darkblue">
        <div className="hidden lg:grid grid-cols-[1fr,450px,1fr] grid-rows-[repeat(7,1fr)]">
          <div className="flex col-start-1 col-end-4 row-start-1 row-end-3 bg-dsm-lightblue">
          </div>
          <div className="col-start-2 col-end-3 row-start-1 row-end-7">
            <div className="shadow-2xl">
              <StaticImage 
                alt="DSM Permit Navigation"
                src="../../images/dsm-permit-navigation.png" 
                placeholder="blurred"/>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <p className="mb-6 text-xl">
          Royal DSM is a global, purpose-led company in Health, Nutrition & Bioscience, applying science 
          to improve the health of people, animals and the planet. DSM’s purpose is to create brighter lives for 
          all. DSM’s products and solutions address some of the world’s biggest challenges while simultaneously 
          creating economic, environmental and societal value for all its stakeholders - customers, employees, 
          shareholders, and society at large. DSM and its associated companies employ approximately 23,000 people 
          around the world and deliver annual net sales of about €10 billion.
        </p>
        <p className="mb-6 text-xl">
          DSM have a sharp focus on safety and use their four eye principle (two people citing everything) to obtain absolute precision. 
          DSM Nutritional products - located in Australia - wanted to digitise their efforts to further reduce error, 
          most notably the tick and forget errors.
        </p>
      </div>
      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-dsm-darkblue my-14">Digitsing permit workflows</h2>
        <p className="mb-6 text-xl">
          The physical solution spans over twenty spreadsheets and cumbersome workflows. We started by 
          reimagining the process and how it would look if it existed in the digital space. A single-page 
          progress web application is how we delivered the solution to the customer.
        </p>
        <p className="mb-6 text-xl">
          Drawing on our experience in the healthcare accreditation industry, we turned practices into rules 
          for the system - so users could not make mistakes.
        </p>
        <p className="mb-6 text-xl">
          The addition of automated reminders for users to action tasks and activities added to the safety experience.
        </p>
        <p className="mb-6 text-xl">
          DSM Nutritional wanted the application to reach far and wide in the organisation, including their Asia-Pacific 
          sites like Malaysia, Singapore, Indonesia and Fiji. We designed the application to be multilingual, including 
          auto-translating responses back into English for the management staff in Australia to review.
        </p>
      </div>
      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-dsm-darkblue my-14">Powered by Linode</h2>
        <p className="mb-6 text-xl">
          Once again we turned to Linode to provide easily accessible Sydney-based infrastructure that met the 
          security requirements of the client.        
        </p>
      </div>

    </Layout>
 );
};
export default PortfolioDSM
